.collapse-arrow .collapse-title::after {
  color: #ddd;
  right: 0.7rem;
}

.sidenav__item {
  //border: 1px solid red;
  width: auto;
  display: flex;
  vertical-align: center;
  height: 45px;
  align-items: center;
  margin: 0 10px 10px;
  font-size: 16px;
  border-radius: 5px;
  text-decoration: none;
  color: var(--sympheny-dark);

  mat-icon,
  &--icon {
    font-size: 34px;
    width: fit-content;
    height: fit-content;
    margin-left: 4px;
    color: var(--sympheny-primary);
    padding: 4px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &--label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.active {
    background-color: var(--sympheny-primary);
    color: white;

    mat-icon,
    &--icon {
      color: white;
      font-weight: 500;
    }
  }

  &--content {
    .sidenav__item {
      background-color: initial;
      font-size: 12px;
      height: 40px;

      &:first-child {
        margin-top: 0;
      }

      &.active,
      &.active mat-icon {
        background-color: initial;
        color: var(--sympheny-primary);
      }

      &:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    }
  }
}
