@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'theme';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';

@import 'colors';
@import 'space';

@import 'components/beta';
@import 'components/snackbar';
@import 'components/sidenav';
@import 'components/table';
@import 'components/app-container';
@import 'components/scenario-stepper';
@import 'components/scenario';
@import 'components/tooltip';
@import 'components/form';
@import 'components/markdown';
@import 'components/radio';
@import 'components/card';
@import 'components/project-card';

@import 'map/marker';
@import 'map/popup';

@import 'openlayers/openlayers';

@import '_text';
@import 'global';
@import 'mat-override';

@layer base {
  a {
    @apply text-primary underline;
  }
}

.hint {
  @apply text-gray-light py-4;
}

.w-full,
.full-width {
  .mat-mdc-form-field {
    width: 100%;
  }
}

.btn:hover {
  @apply bg-gray-100 border-gray-100;
}

.btn-primary {
  @apply text-white;

  &:hover {
    @apply bg-primary opacity-80;
  }
}

body {
  @apply text-sm;
}
