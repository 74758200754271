// // Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field-infix,
.mdc-text-field--outlined div {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0;
}

// Reset padding
.mdc-text-field,
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-flex {
  padding: 0 !important;
}
.mat-mdc-floating-label {
  top: 24px !important;
}
.mdc-floating-label--float-above {
  top: 18px !important;
}
.mat-mdc-form-field-infix {
  max-height: 39px !important;

  min-height: 39px !important;
  padding-top: 14px !important;
}

mat-hint {
  @apply text-gray-400;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(-2px) !important;
}

.mat-datepicker-toggle {
  button {
    padding: 0px 12px !important;
    height: 39px !important;
    width: 39px !important;
  }
}
