:root {
  --sympheny-beta-color: #fac710;
}
.beta {
  &--text {
    color: var(--sympheny-beta-color);
  }
  &--ribon {
    &:before {
      position: absolute;
      width: 80px;
      height: 18px;
      font-weight: 500;
      font-size: 14px;
      top: 10px;
      left: -20px;
      background: var(--sympheny-beta-color);
      text-align: center;
      content: 'Beta';
      transform: rotate(-45deg);
      z-index: 999;
    }
  }
}
