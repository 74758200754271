//__<<ngThemingMigrationEscapedComment0>>__
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined';

@import 'prismjs/themes/prism-okaidia.css';
@import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
//@import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

// General styles
* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.text-muted {
  color: #979797;
}

.text-wrap {
  word-wrap: break-word;
}

a {
  color: var(--sympheny-primary);
}

sup {
  position: relative;
  vertical-align: baseline;
  top: -4px;
  font-size: 80%;
}

.text-center {
  text-align: center !important;
}

.mat-expansion-panel-header {
  margin-bottom: 10px;
}

.mat-expansion-panel-body {
  padding: 0 24px 8px;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.sele {
  background-color: #f2f2f2;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.scenario-list .mat-tab-labels {
  display: flex;
  justify-content: space-evenly !important;
  width: 700px !important;
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    border-radius: 5px !important;
    background: #aaa !important;
    color: white !important;
    font-size: 15px !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label-active {
    background: var(--sympheny-primary) !important;
    border: solid var(--sympheny-primary) 1px;
  }
}

@keyframes spinner_button {
  to {
    transform: rotate(360deg);
  }
}
