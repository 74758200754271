:root {
  --sympheny-space-small: 5px;
  --sympheny-space: 10px;
  --sympheny-space-large: 15px;
  --sympheny-space-xlarge: 20px;
  --sympheny-header: 64px;
  --sympheny-sidebar: 72px;
}

.m-2 {
  margin: 8px;
}

.pb-2 {
  padding-bottom: 8px;
}

.p-20 {
  padding: 80px;
}

$sizes: 0 2 4 8 12 16 20 24 32 40 48 56 64 80;

@each $size in $sizes {
  // padding
  .u-p-#{$size} {
    padding: #{$size}px;
  }

  .u-px-#{$size} {
    padding-left: #{$size}px;
    padding-right: #{$size}px;
  }

  .u-py-#{$size} {
    padding-top: #{$size}px;
    padding-bottom: #{$size}px;
  }

  .u-pt-#{$size} {
    padding-top: #{$size}px;
  }

  .u-pr-#{$size} {
    padding-right: #{$size}px;
  }

  .u-pb-#{$size} {
    padding-bottom: #{$size}px;
  }

  .u-pl-#{$size} {
    padding-left: #{$size}px;
  }

  // important

  .u-p-#{$size}-important {
    padding: #{$size}px !important;
  }

  .u-px-#{$size}-important {
    padding-left: #{$size}px !important;
    padding-right: #{$size}px !important;
  }

  .u-py-#{$size}-important {
    padding-top: #{$size}px !important;
    padding-bottom: #{$size}px !important;
  }

  .u-pt-#{$size}-important {
    padding-top: #{$size}px !important;
  }

  .u-pr-#{$size}-important {
    padding-right: #{$size}px !important;
  }

  .u-pb-#{$size}-important {
    padding-bottom: #{$size}px !important;
  }

  .u-pl-#{$size}-important {
    padding-left: #{$size}px !important;
  }

  // margin
  .u-m-#{$size} {
    margin: #{$size}px;
  }

  .u-mx-#{$size} {
    margin-left: #{$size}px;
    margin-right: #{$size}px;
  }

  .u-my-#{$size} {
    margin-top: #{$size}px;
    margin-bottom: #{$size}px;
  }

  .u-mt-#{$size} {
    margin-top: #{$size}px;
  }

  .u-mr-#{$size} {
    margin-right: #{$size}px;
  }

  .u-mb-#{$size} {
    margin-bottom: #{$size}px;
  }

  .u-ml-#{$size} {
    margin-left: #{$size}px;
  }
}
